<template>
    <Layout>
      <PageHeader :title="title" :items="items" />
      <div class="row" style="min-height: 78vh;">
        <div class="col-12">
          <div class="card h-100">
            <div class="card-body">
              <!-- Table -->
              <div class="table-responsive mb-0 h-100">
                <b-table
                  class="table table-centered table-nowrap"
                  :items="documents"
                  :fields="fields"
                  responsive="sm"
                  :per-page="perPage"
                  :current-page="currentPage"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  @filtered="onFiltered"
                >
                </b-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  </template>
  
  <script>
  import Layout from "@/views/layouts/main";
  import PageHeader from "@/components/page-header";
 
  
  /**
   * User list component
   */
  export default {
    components: { Layout, PageHeader },
    page: {
      title: "Documenti",
      // meta: [
      //   {
      //     name: "description",
      //     content: appConfig.description,
      //   },
      // ],
    },
    data() {
      return {
        title: "Documenti",
        items: [
          {
            text: this.$auth.user().store.company_name,
          },
          {
            text: "Documenti",
            active: true,
          },
        ],
        store: this.$auth.user().store,
        documents: [
            {
                name: "Manuale di utilizzo del sistema AdaptaEasy",
                description: "Lorem ipsum",
                file_type: "PDF",
                last_update: "22-10-2022",
                link: '/assets/documents/doc1.pdf',
            },
            {
                name: "Termini di licenza",
                description: "Lorem ipsum",
                file_type: "PDF",
                last_update: "22-10-2022",
                link: '/assets/documents/doc1.pdf',
            },
            {
                name: "Informativa privacy",
                description: "Lorem ipsum",
                file_type: "PDF",
                last_update: "22-10-2022",
                link: '/assets/documents/doc1.pdf',
            },
        ],
        totalRows: 1,
        currentPage: 1,
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        filter: null,
        filterOn: [],
        sortBy: "name",
        sortDesc: false,
        fields: [
          {
            key: "name",
            label: "Nome documento",
          },
          {
            key: "description",
            label: "Descrizione",
          },
          {
            key: "last_update",
            label: "Ultimo aggiornamento"
          },
          {
            key: "file_type",
            label: "Formato file"
          },
          "Azioni",
        ],
      };
    },
    computed: {
      /**
       * Total no. of records
       */
      rows() {
        return this.userList.length;
      },
    },
    mounted() {
    },
    methods: {
      /**
       * Search the table data with search input
       */
      onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length;
        this.currentPage = 1;
      },
    },
    middleware: "authentication",
  };
  </script>
  